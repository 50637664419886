import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup,Table,TableHead,TableBody,TableCell,TableRow,TableContainer } from "@mui/material";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const AddNewPOLL = ({NewPOLL,Poll,HandleSubmit,PullOption,PublishPoll,closingModal}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 
  const [question,setQuestion] = useState(NewPOLL?'':Poll.Question)
  const [pollOptionText,setPollOptionText] = useState('')


    const HandleSubmitBtn = () =>{
      if(NewPOLL) HandleSubmit(question)
      else{
        HandleSubmit(pollOptionText)
        setPollOptionText('')
      }
      
    }

    const PullOptionHandle = (id) =>{
      PullOption(id)
    }

    const PublishPollHandle = () =>{
      PublishPoll()
    }
  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height: NewPOLL || Poll.Options.length <3? "50%" : "80%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>

  
        
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewPOLL?`Add New Poll` : `Edit Poll`}</Typography>
      
      <Typography variant="h6" sx={{textAlign:"center",mt:1,mb:1}}>{!NewPOLL?Poll.Question : `Please ensure careful review before submitting the Poll, as editing or deletion will not be possible once created`}</Typography>
      <Divider />
      {NewPOLL ? <TextField sx={{mt:3}} fullWidth  variant="filled" type="text" value={question} onChange={(e)=>setQuestion(e.target.value)} label="Poll Question ..." color="secondary"/> :(<></>)}
      
      {NewPOLL? (<Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Poll</Button>):(<></>)}
     
      {!NewPOLL && Poll.Options.length < 5 && !Poll.isActive ? (<><TextField sx={{mt:3,width:'60%'}}   variant="filled" type="text" value={pollOptionText} onChange={(e)=>setPollOptionText(e.target.value)} label="Poll Option Text..." color="secondary"/><Button onClick={HandleSubmitBtn} sx={{mt:4,ml:3}} variant="contained" color="secondary">Add New Option</Button></>) :(<></>) }

      {!NewPOLL ?
       ( <>
          <TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Option text</TableCell>
        <TableCell>Votes Nr</TableCell>
        <TableCell>ِAction</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
  {Poll.Options.map((op) => (
    <TableRow>
      <TableCell>{op.text}</TableCell>
      <TableCell>{op.votes_nr}</TableCell>
      <TableCell>
        <IconButton>
          <RemoveRedEyeIcon />
        </IconButton>
        {!Poll.isActive ? (
          <IconButton>
            <DeleteOutlineIcon
              onClick={() => PullOptionHandle(op.id)}
              color="error"
            />
          </IconButton>
        ) : (
          <></>
        )}
      </TableCell>
    </TableRow>
  ))}
</TableBody>
    </Table>
    </TableContainer>
        </>  ): (<></>) 
      }

      {!NewPOLL && !Poll.isActive  && Poll.Options.length>1? (<><Typography sx={{mt:3}} color="error">Once you publish you can't undo </Typography> <Button onClick={PublishPollHandle} sx={{mt:2}} variant="contained" color="secondary">Publish</Button></>) : (<></>) }
    </Box>
  );
};
export default AddNewPOLL;