import { Box, Button, TextField,useTheme,Typography,TextareaAutosize ,IconButton} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState } from "react";
import { ReactTagify } from "react-tagify";
import CloseIcon from '@mui/icons-material/Close';

const CategoryConfig = ({NewCat,Category,SubmitCategory,closingModal,handleImaeg}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const tagifyRef = useRef()
  const [tags,setTags] = useState(NewCat?[]:Category.seo.metaKeywords)
  const [title,SetTitle] = useState(NewCat?"":Category.MainInfo.title)
  const [description,SetDescription] = useState(NewCat?"":Category.seo.metaDescription)
  const colors = tokens(theme.palette.mode);

  const handleFormSubmit = (values) => {
    console.log(values);
  };
  
  const HandleSubmitBtn = () =>{
    SubmitCategory(title,description,tags)
  }

  const handleImaegBtn =() =>{
    handleImaeg()
  }

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewCat?(`Add New Category`):(`Edit Category`)}</Typography>
      <Formik
        onSubmit={handleFormSubmit}
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>

            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Category Title ..."
                defaultValue={title}
                name="firstName"
                onChange={(e)=>SetTitle(e.target.value)}
                sx={{ gridColumn: "span 2",mt:3 }}
                color="secondary"

              />
             
      <Tags  defaultValue={tags} tagifyRef={tagifyRef} color="secondary" settings={{
        placeholder:"Seo Keywords ..."
      }} onChange={(e) => setTags(e.detail.tagify.value.map((tag) => tag.value))} />

<TextField
fullWidth
          id="filled-multiline-static"
          label="Seo Description ..."
          multiline
          rows={4}
          sx={{ gridColumn: "span 2",mt:3 }}
          onChange={(e)=>SetDescription(e.target.value)}
          defaultValue={description}
          variant="filled"
          color="secondary"

        />
                    <Button onClick={HandleSubmitBtn} sx={{
                      mt:2
                    }} variant="contained" color="secondary">{NewCat? `Add New Category` : `Edit Category`}</Button>
                    {!NewCat ? <><Button onClick={handleImaegBtn} sx={{
                      mt:2,mx:2
                    }} variant="contained" color="warning">Edit Image</Button></> :<></>}
    </form>
      )}
   
      </Formik>
      
    
    </Box>
  );
};
export default CategoryConfig;