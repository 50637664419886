import Header from "../../components/Header"
import {Typography,Box,useTheme} from '@mui/material'

const Dashboard = () =>{
    return(
        <Box m="20px">
            <Box sx={{
                height:"60vh",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"center",
                gap:2
            }}>
                <Typography color={`secondary`} sx={{fontWeight:"bold"}} variant="h2">Welcome To Time4News Dashboard</Typography>
                <Typography>Created By ULCode & Powered By U</Typography>
            </Box>
        </Box>
    )
}

export default Dashboard