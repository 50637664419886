import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { ReactTagify } from "react-tagify";
import { getAuthentication } from "../../auth";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
  } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { UPLOAD_API } from "../../data/Links";
const API_URL = UPLOAD_API;

const PortfolioImageConfig = ({Portfolio,EditModalConfigStatus,UpdateImgData,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


    const [crop, setCrop] = useState()
    const imgRef = useRef(null)
    const [imgSrc, setImgSrc] = useState('')
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(16 / 9)
    const [uploadedFileName,setUploadedFileName] = useState(Portfolio.MainImage.filepath? Portfolio.MainImage.filepath : '')
    const [imageFile, setImageFile] = useState(null)
    const [alt,setAlt] = useState(Portfolio.MainImage.alt? Portfolio.MainImage.alt : '')

    const centerAspectCrop = (mediaWidth,mediaHeight,aspect,) => {
        return centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
          ),
          mediaWidth,
          mediaHeight,
        )
      }

      const  onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setCrop(undefined) // Makes crop preview update between images.
          const reader = new FileReader()
          reader.addEventListener('load', () =>
            setImgSrc(reader.result?.toString() || ''),
          )
          reader.readAsDataURL(e.target.files[0])

          console.log(imgSrc)
        }
    }
    const onImageLoad = (e) => {
        if (aspect) {
          const { width, height } = e.currentTarget
          setCrop(centerAspectCrop(width, height, aspect))
        }
      }

  const handleFormSubmit = (values) => {
    console.log(values);
  };
  





  const HandleSubmitBtn = () =>{
    UpdateImgData(alt,uploadedFileName)
  }

  const uploadFile = async () => {
    try {
      const canvas = document.createElement("canvas");
      const { x, y, width, height } = completedCrop;
  
      if (!completedCrop || !imgRef.current) {
        return;
      }
  
      const image = imgRef.current;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
  
      canvas.width = width * scaleX;
      canvas.height = height * scaleY;
  
      const ctx = canvas.getContext("2d");
  
      ctx.drawImage(
        image,
        x * scaleX,
        y * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
  
      const croppedImage = await new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg", // or "image/png"
          .8 // Quality parameter: 0 to 1, where 1 is highest quality
        );
      });
  
      const formData = new FormData();
      formData.append("image", croppedImage);
  
      console.log("image is ", formData.get('image'));
  
      const res = await axios.post(API_URL, formData, {
        headers: {
          token: `Bearer ${getAuthentication()}`,
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (res.status === 201) {
        setUploadedFileName(res.data.name);
      }
    } catch (error) {
      console.error(error);
    }
  }

 

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height:"80%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
        <IconButton onClick={()=>EditModalConfigStatus("CONFIG")} >
        <ArrowBackIcon />
    </IconButton>
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Portfolio Image</Typography>
      <Typography variant="h6" sx={{
        textAlign:"center",
        mt:1,
        mb:1
      }}>{Portfolio.MainInfo.title}</Typography>
      <Divider />
      <Box>

      <TextField 
              sx={{
                mt:3
              }}
              fullWidth 
              variant="filled"
              type="text"
              value={alt}
              onChange={(e)=>setAlt(e.target.value)}
              label="Image Description ..."
              color="secondary"
            />
      </Box>
      <Box sx={{display:"flex",justifyContent:"flex-start",mt:1}}>
                <Button sx={{mx:1}} color='primary' variant="contained" component="label"> Browse Image <input type="file" accept="image/*" onChange={onSelectFile} hidden/></Button>
                <Button sx={{mx:1}} color='warning' variant="contained" component="label" onClick={uploadFile}> Upload Image</Button>

            </Box>
            <Box sx={{width:"60%",mx:"auto",mt:4,}}>
                    {!!imgSrc && (
                    <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={(c) => setCompletedCrop(c)} aspect={aspect}>
                        <img ref={imgRef} alt="Crop me" src={imgSrc} style={{ transform: `scale(${scale}) rotate(${rotate}deg)`}} onLoad={onImageLoad}/>
                    </ReactCrop>
                    )}
            </Box>
            {
                imgSrc !== '' ? (<Typography sx={{textAlign:"center"}} color={uploadedFileName !== '' ? "secondary" : "error"}>{uploadedFileName !== '' ? "Image Uploaded Successfully" : "Pending !!"}</Typography>) :(<></>)
            }
            

            {uploadedFileName !== '' ? (<Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Update Portfolio Image</Button>) : (<></>)}
    
    </Box>
  );
};
export default PortfolioImageConfig;