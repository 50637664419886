import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getAuthentication } from "../../auth";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import { UPLOAD_API } from "../../data/Links";

const API_URL = UPLOAD_API;

const AddAttachArtConfig = ({Article,AddAttachToArt,EditModalConfigStatus,closingModal}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 
  const [atchType,setAttachType] = useState('IMG')
  const [imgAlt,setImgAlt]=useState('')
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName,setFileName] = useState('')
  const [link,setLink]=useState('')
  const [linkName,setLinkName] = useState('')  
  const [ifrm,setIfrm] =useState('')
  const [ifrmTitle,setIfrmTitle] = useState('')
  const [err,setErr] = useState('')
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  }
  const selectedComp = {
    "IMG":(<> <TextField  sx={{mt:3}} fullWidth variant="filled" type="text" value={imgAlt} onChange={(e)=>setImgAlt(e.target.value)} label="Image Description ..." color="secondary" /><Button sx={{mt:1}} fullWidth color='secondary' variant="outlined" component="label"> Browse Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button></>),
    "LINK":(<> <TextField  sx={{mt:3}} fullWidth variant="filled" type="text" value={link} onChange={(e)=>setLink(e.target.value)} label="Link ..." color="secondary" /><TextField  sx={{mt:3}} fullWidth variant="filled" type="text" value={linkName} onChange={(e)=>setLinkName(e.target.value)} label="Link Name ..." color="secondary" /></>),
    "IFRM":(<> <TextField  sx={{mt:3}} fullWidth variant="filled" type="text" value={ifrm} onChange={(e)=>setIfrm(e.target.value)} label="Iframe Link ..." color="secondary" /><TextField  sx={{mt:3}} fullWidth variant="filled" type="text" value={ifrmTitle} onChange={(e)=>setIfrmTitle(e.target.value)} label="Iframe Title ..." color="secondary" /></>),
  }
  const UploadFile = async() =>{
        const formData = new FormData();
        formData.append('image', selectedFile);
        const res = await  axios.post(API_URL, formData, {
          headers: {
            token:`Bearer ${getAuthentication()}`,
            'Content-Type': 'multipart/form-data'
          }
        })
        setFileName(res.data.name)

  }
  useEffect(() => {
    if (fileName !== '') {
        AddAttachToArt(atchType,fileName,imgAlt)
    }
}, [ fileName])

    const HandleSubmitBtn = () =>{
        if(atchType === "IMG"){
            if(selectedFile){
                UploadFile()
            }
            else{
                setErr('Your Should Select A File First')
            }
        }
        else if(atchType === 'LINK'){
            AddAttachToArt(atchType,link,linkName)
        }
        else{
            AddAttachToArt(atchType,ifrm,ifrmTitle)
        }
    }
  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
                <IconButton onClick={()=>EditModalConfigStatus("CONFIG")}  >
        <ArrowBackIcon />
    </IconButton>
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Add Attachment To Article</Typography>
      <Typography variant="h6" sx={{
        textAlign:"center",
        mt:1,
        mb:1
      }}>{Article.MainInfo.title}</Typography>
      
      <FormControl fullWidth sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel color="secondary" id="demo-simple-select-label">Select Attachment Type ...</InputLabel>
                <Select variant="filled"  value={atchType} onChange={(e)=>setAttachType(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Attachment Type ..." >
                    <MenuItem key={"ATCHIMG"} value="IMG">Image</MenuItem>
                    <MenuItem key={"ATCHIMG"} value="LINK">Link</MenuItem>
                    <MenuItem key={"ATCHIMG"} value="IFRM">Iframe</MenuItem>
                </Select>
     </FormControl>
     {
        selectedComp[atchType]
     }
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add Article Attachment</Button>
    {
        err !== '' ? (<Typography variant="h6" color="error" sx={{textAlign:"center"}}>{err}</Typography>) : (<></>)
    }
    

    </Box>
  );
};
export default AddAttachArtConfig;