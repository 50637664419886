import { Box, Button, Divider, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup,IconButton } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { ReactTagify } from "react-tagify";
import { getAuthentication } from "../../auth";
import axios from "axios";
import InfoIcon from '@mui/icons-material/Info';
import ImageIcon from '@mui/icons-material/Image';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LanguageIcon from '@mui/icons-material/Language';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { GLOBAL_API } from "../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const ArticleConfig = ({Article,UpdateArticleOption,EditModalConfigStatus,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [onHeader,setOnHeader] = useState(Article.options.onHeader)
  const [published,setPublished] = useState(Article.options.published)

 


  const  checkRequiredFields = (obj) => {
   const requiredFields = [
    { path: "MainInfo.title", message: "Title is empty" },
    { path: "MainImage.alt", message: "Image description is empty" },
    { path: "MainImage.filepath", message: "There is no uploaded image" },
    { path: "content", message: "Content is empty" },
    { path: "seo.description", message: "SEO description is empty" },
    { path: "seo.keywords", message: "SEO keywords are empty" }
  ];

  const emptyFields = [];
  let isAllFieldsEmpty = true;

  for (const field of requiredFields) {
    const fieldValue = getFieldByPath(obj, field.path);

    if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
      emptyFields.push(field.message);
    }

    if (field === requiredFields[requiredFields.length - 1] && emptyFields.length > 0) {
      isAllFieldsEmpty = false;
    }
  }

  return {
    isAllFieldsEmpty,
    emptyFields: emptyFields
  };
  }
  
  // Helper function to get field value by path
  const  getFieldByPath =(obj, path) => {
    const fields = path.split(".");
  
    let value = obj;
    for (const field of fields) {
      if (value && value.hasOwnProperty(field)) {
        value = value[field];
      } else {
        value = undefined;
        break;
      }
    }
  
    return value;
  }
  


  const handleFormSubmit = (values) => {
    console.log(values);
  };
  
  const upDateHeaderState = () =>{
    setOnHeader(!onHeader)
    UpdateArticleOption(!onHeader,published)
  }

  const upDatePublishState = () =>{
    setPublished(!published)
    UpdateArticleOption(onHeader,!published)
  }

 

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
            <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>

      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Config Article</Typography>
      <Typography variant="h6" sx={{
        textAlign:"center",
        mt:1,
        mb:1
      }}>{Article.MainInfo.title}</Typography>
      <Divider />
      <Formik
        onSubmit={handleFormSubmit}
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    fullWidth
                    sx={{mt:2}} color="secondary" >
                            <Button key="ArticleMainInfoNav" onClick={()=>EditModalConfigStatus('ADD')}><InfoIcon sx={{mx:1}} /> Main Info</Button>
                            <Button key="ArticleMainImageNav" onClick={()=>EditModalConfigStatus('IMG')}><ImageIcon sx={{mx:1}} /> Main Image</Button>
                            <Button key="ArticleContentNav" onClick={()=>EditModalConfigStatus('CNT')}><FileCopyIcon sx={{mx:1}} /> Content</Button>
                            <Button key="ArticleSEONav" onClick={()=>EditModalConfigStatus('SEO')}><LanguageIcon sx={{mx:1}} /> SEO</Button>
                            <Button key="ArticleAttachment" onClick={()=>EditModalConfigStatus('ATCH')}><AttachFileIcon sx={{mx:1}} /> Attachments</Button>
                            <Button key="ArticleAttachment" onClick={()=>EditModalConfigStatus('ADDATCH')}><AttachFileIcon sx={{mx:1}} /> Add Attachment</Button>
                </ButtonGroup>
              
                  <Box sx={{m:1}}>
                  { checkRequiredFields(Article).emptyFields.map(msg=>(<Typography color="error">{msg}</Typography>))}

                  </Box>
                
                <FormControlLabel control={<Checkbox color="secondary" checked={onHeader}  />}  label="Set On Header" onChange={upDateHeaderState} />
                <FormControlLabel control={<Checkbox       disabled={!checkRequiredFields(Article).isAllFieldsEmpty || Article.options.published === true}  color="secondary" checked={published}  />}  label="Publish Article" onChange={upDatePublishState} />

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default ArticleConfig;