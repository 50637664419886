import { Box, Button, TextField,useTheme,Typography,RadioGroup,FormLabel,Radio,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect,useCallback  } from "react";
import { ReactTagify } from "react-tagify";
import { getAuthentication } from "../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { GLOBAL_API } from "../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const AddNewGame = ({NewArt,Game,HandleMainInfoSubmit,EditModalConfigStatus,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const tagifyRef = useRef()

  const [title,setTtiel] = useState(NewArt?'':Game.MainInfo.title)
  const [ar,setAr] = useState(NewArt?'':Game.MainInfo.names.ar)
  const [en,setEn] = useState(NewArt?'':Game.MainInfo.names.en)
  const [isAuthor,setIsAuthor] = useState(NewArt?false:Game.MainInfo.author.exist)
  const [authorName,setAuthorName] = useState(NewArt?false:Game.MainInfo.author.author_name)
  const [category,setCategory] = useState(NewArt?'':Game.MainInfo.category.id)
  const [isSource,setIsSource] = useState(NewArt?false:Game.MainInfo.source.exist)
  const [sourceName,setSourceName] = useState(NewArt?'':Game.MainInfo.source.name)
  const [sourceLink,setSourceLink] = useState(NewArt?'':Game.MainInfo.source.link)
  const [isBirth,setIsBirth] = useState(NewArt?'birthdate':Game.MainInfo.startDate.isBirth?'birthdate':'startdate')
  const [endDate,setEndDate] = useState(NewArt?'deathdate':Game.MainInfo.endDate.isDeath?'deathdate':'enddate')
  const [startPlace,setStartPlace] = useState(NewArt?'birthplace':Game.MainInfo.startPlace.isBirth?'birthplace':'endplace')
  const [owners,setOwners] = useState(NewArt?[]:Game.MainInfo.owners)
  const [startDateValue,setStartDateValue]= useState(NewArt?dayjs():dayjs(Game.MainInfo.startDate.date))
  const [endDateValue,setEndDateValue]= useState(NewArt?dayjs():dayjs(Game.MainInfo.endDate.date))
  const [startPlaceValue,setStartPlaceValue]= useState(NewArt?'':Game.MainInfo.startPlace.place)

  const [categoriesList,setCategoriesList] = useState([])

  const handleFormSubmit = (values) => {
    console.log(values);
  };
  
  const HandleSubmitBtn = () =>{
    HandleMainInfoSubmit(title,ar,en,isAuthor,authorName,category,isSource,sourceName,sourceLink,isBirth === "birthdate"?true:false,endDate === 'deathdate'?true:false,startPlace === 'birthplace' ? true:false,startDateValue,endDateValue,startPlaceValue,owners)
  }

  const handleStartDateChange = useCallback((date) => {
    setStartDateValue(date);
  }, [])

  const fetchData = async () => {
    try{
 
   const res = await axios.post(API_URL,{
        query:`query{
          GetAllGameCategory{
            id
            MainInfo{
              title
            }
            seo{
              metaDescription
              metaKeywords
            }
            info{
              articleCount
            }
          }
        }`
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.status === 200){
      setCategoriesList(res.data.data.GetAllGameCategory)
    }
  }
  catch(err){

  }
  }

  useEffect(() => {
    fetchData()
    }, [])

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"90%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      {
        !NewArt? (<IconButton onClick={()=>EditModalConfigStatus("CONFIG")}  >
        <ArrowBackIcon />
    </IconButton>) : (<></>)
      }
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewArt ? "Add New Game" : "Edit Game"}</Typography>
      {!NewArt ?(<Typography variant="h6" sx={{
        textAlign:"center"
      }}>{Game.MainInfo.title}</Typography>):(<></>) }
      <Formik
        onSubmit={handleFormSubmit}
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
            <TextField 
              sx={{
                mt:3
              }}
              fullWidth 
              variant="filled"
              type="text"
              value={title}
              onChange={(e)=>setTtiel(e.target.value)}
              label="Game Title ..."
              color="secondary"
            />
            <Box sx={{display:"flex",justifyContent:"space-between"}}>
            <TextField sx={{mt:3,width:"48%"}}variant="filled"type="text" value={ar} onChange={(e)=>setAr(e.target.value)}label="Game Name Arabic ..."color="secondary"/>
            <TextField sx={{mt:3,width:"48%"}}variant="filled"type="text" value={en} onChange={(e)=>setEn(e.target.value)}label="Game Name English ..."color="secondary"/>
            </Box>
            <FormControl fullWidth sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel color="secondary" id="demo-simple-select-label">Select Category ...</InputLabel>
                <Select variant="filled"  value={category} onChange={(e)=>setCategory(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Category ..." >
                  {categoriesList.map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.MainInfo.title}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{mt:2}} >
                <Box sx={{width:"100%"}}><FormLabel color="secondary" id="demo-row-radio-buttons-group-label">Start Date</FormLabel></Box>
                <Box sx={{display:"flex",justifyConten:"space-bettween",alignContent:"center",alignItems:"center"}}>
                <FormControl sx={{width:"48%"}}>
                          <RadioGroup value={isBirth} onChange={(e)=>setIsBirth(e.target.value)} sx={{alignContent:"center",alignItems:"center",height:"100%",justifyContent:"space-around"}} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                              <FormControlLabel value="birthdate" control={<Radio color="secondary" />} label="Birthdate" />
                              <FormControlLabel value="startdate" control={<Radio color="secondary" />} label="Start Date" />
                          </RadioGroup>
                  </FormControl>   

                  <LocalizationProvider   dateAdapter={AdapterDayjs}>
                    <DatePicker value={startDateValue} onChange={handleStartDateChange} sx={{width:"48%"}} color="secondary" />
                  </LocalizationProvider>  
                </Box>
                  
              </Box>
              <Box sx={{mt:2}} >
                <Box sx={{width:"100%"}}><FormLabel color="secondary" id="demo-row-radio-buttons-group-label">End Date</FormLabel></Box>
                <Box sx={{display:"flex",justifyConten:"space-bettween",alignContent:"center",alignItems:"center"}}>
                <FormControl sx={{width:"48%"}}>
                          <RadioGroup value={endDate} onChange={(e)=>setEndDate(e.target.value)} sx={{alignContent:"center",alignItems:"center",height:"100%",justifyContent:"space-around"}} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                              <FormControlLabel value="deathdate" control={<Radio color="secondary" />} label="Death Date" />
                              <FormControlLabel value="enddate" control={<Radio color="secondary" />} label="End Date" />
                          </RadioGroup>
                  </FormControl>   

                  <LocalizationProvider  dateAdapter={AdapterDayjs}>
                    <DatePicker value={endDateValue} onChange={(newValue)=>setEndDateValue(newValue)} sx={{width:"48%"}} color="secondary" />
                  </LocalizationProvider>  
                </Box>
              </Box>
              <Box sx={{mt:2}} >
                <Box sx={{width:"100%"}}><FormLabel color="secondary" id="demo-row-radio-buttons-group-label">Start Place</FormLabel></Box>
                <Box sx={{display:"flex",justifyConten:"space-bettween",alignContent:"center",alignItems:"center"}}>
                <FormControl sx={{width:"48%"}}>
                          <RadioGroup value={startPlace} onChange={(e)=>setStartPlace(e.target.value)} sx={{alignContent:"center",alignItems:"center",height:"100%",justifyContent:"space-around"}} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                              <FormControlLabel value="birthplace" control={<Radio color="secondary" />} label="Place of birth" />
                              <FormControlLabel value="endplace" control={<Radio color="secondary" />} label="End Place" />
                          </RadioGroup>
                  </FormControl>   
                  <TextField sx={{width:"48%"}}variant="filled"type="text"value={startPlaceValue}onChange={(e)=>setStartPlaceValue(e.target.value)}label="Place Name ..."color="secondary"/>

                
                </Box>
                <Box>
                <Tags  defaultValue={owners} tagifyRef={tagifyRef} color="secondary" settings={{placeholder:"Owners ..."}} onChange={(e) => setOwners(e.detail.tagify.value.map((tag) => tag.value))} />
                </Box>
              </Box>
              <FormControl  fullWidth sx={{ mt: 2, minWidth: 120 }}>
                <FormControlLabel control={<Checkbox color="secondary" checked={isAuthor}  />}  label="Author" onChange={()=>setIsAuthor(!isAuthor)} />
                <TextField disabled={!isAuthor} value={authorName} onChange={(e)=>setAuthorName(e.target.value)}  variant="filled" type="text" label="Author Name ..." color="secondary" />
              </FormControl>
                <FormControl  fullWidth sx={{ mt: 2, minWidth: 120 }}>
                  <FormControlLabel control={<Checkbox color="secondary" checked={isSource}  />}  label="Source" onChange={()=>setIsSource(!isSource)} />
                  <Box sx={{
                    display:"flex",
                    justifyContent:"space-between"
                  }}>
                      <TextField disabled={!isSource} value={sourceName}  onChange={(e)=>setSourceName(e.target.value)} sx={{width:"48%"}}  variant="filled" type="text" label="Source Name ..." color="secondary" />
                      <TextField disabled={!isSource} value={sourceLink}  onChange={(e)=>setSourceLink(e.target.value)} sx={{width:"48%"}}  variant="filled" type="text" label="Source Link ..." color="secondary" />
                  </Box>
              </FormControl>  
            <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewArt? `Add New Game` : `Edit Game`}</Button>

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewGame;