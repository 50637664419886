import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';

import JoditEditor from 'jodit-react';
import { GLOBAL_API } from "../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const ArticleContent = ({Article,UpdateContentData,EditModalConfigStatus,closingModal}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 
  const [value, setValue] = useState('')
    const editor = useRef(null)
    const [content, setContent] = useState(Article.content?Article.content:'')
    const copyStringToClipboard = (str)  =>{
      var el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style = { position: "absolute", left: "-9999px" };
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    }
    const facilityMergeFields = [
      "FacilityNumber",
      "FacilityName",
      "Address",
      "MapCategory",
      "Latitude",
      "Longitude",
      "ReceivingPlant",
      "TrunkLine",
      "SiteElevation"
    ];
    const inspectionMergeFields = [
      "InspectionCompleteDate",
      "InspectionEventType"
    ];
    const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
      let optionGroupElement = document.createElement("optgroup");
      optionGroupElement.setAttribute("label", optionGrouplabel);
      for (let index = 0; index < mergeFields.length; index++) {
        let optionElement = document.createElement("option");
        optionElement.setAttribute("class", "merge-field-select-option");
        optionElement.setAttribute("value", mergeFields[index]);
        optionElement.text = mergeFields[index];
        optionGroupElement.appendChild(optionElement);
      }
      return optionGroupElement;
    }
    const buttons = [
      "undo",
      "redo",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "superscript",
      "subscript",
      "|",
      "align",
      "|",
      "ul",
      "ol",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "link",
      "table",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "fullsize",
      "selectall",
      "print",
      "|",
      "source",
      "|",
      {
        name: "insertMergeField",
        tooltip: "Insert Merge Field",
        iconURL: "images/merge.png",
        popup: (editor, current, self, close) => {
          function onSelected(e) {
            let mergeField = e.target.value;
            if (mergeField) {
              console.log(mergeField);
              editor.selection.insertNode(
                editor.create.inside.fromHTML("{{" + mergeField + "}}")
              );
            }
          }
          let divElement = editor.create.div("merge-field-popup");
    
          let labelElement = document.createElement("label");
          labelElement.setAttribute("class", "merge-field-label");
          labelElement.text = 'Merge field: ';
          divElement.appendChild(labelElement);
    
          let selectElement = document.createElement("select");
          selectElement.setAttribute("class", "merge-field-select");
          selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
          selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
          selectElement.onchange = onSelected;
          divElement.appendChild(selectElement);
    
          console.log(divElement);
          return divElement;
        }
      },
      {
        name: "copyContent",
        tooltip: "Copy HTML to Clipboard",
        iconURL: "images/copy.png",
        exec: function(editor) {
          let html = editor.value;
          copyStringToClipboard(html);
        }
      }
    ];
    
    const editorConfig = {
      readonly: false,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      //defaultActionOnPaste: "insert_clear_html",
      buttons: buttons,
      uploader: {
        insertImageAsBase64URI: true
      },
      style : {
          color: '#202020',
        },
      width: "100%",
      height: "60vh"

    }

    const HandleSubmitBtn = () =>{
        UpdateContentData(content)
    }
  return (
    <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        height:"95%",
        bgcolor: colors.primary[400],
        overflowY:'scroll',
        boxShadow: 24,
        p: 4}}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
        <IconButton onClick={()=>EditModalConfigStatus("CONFIG")}  >
        <ArrowBackIcon />
    </IconButton>
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Article Content</Typography>
      <Typography variant="h6" sx={{
        textAlign:"center",
        mt:1,
        mb:1
      }}>{Article.MainInfo.title}</Typography>
      <Divider />
      <JoditEditor 
                    ref={editorConfig}
                    value={content}
                    config={editorConfig}
                    tabIndex={1} 
                    onBlur={newContent => setContent(newContent)} 
			        onChange={newContent => {}}
                />
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Update Article Content</Button>
    </Box>
  );
};
export default ArticleContent;