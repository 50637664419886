import { Box,useTheme,Pagination,TableContainer,Table,TableBody,TableHead,TableCell,Paper,TableRow,TablePagination,Button,IconButton,Modal} from "@mui/material"
import { useState,useEffect } from "react";
import Header from "../../../components/Header"
import { tokens } from "../../../theme";
import axios from "axios";
import { getAuthentication } from "../../../auth";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CategoryConfig from "../../../components/CategoryConfig";
import ImageConfig from "../../../components/Article/ArticleImageConfig";
import CircularProgress from '@mui/material/CircularProgress';
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const Categories = ({CategoryType}) =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [categories,SetCategories] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [toAddNew,setToAddNew] = useState(true)
    const [editedCat,setEditedCat] = useState({})
    const [handlingImage,setHandlingImage] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')

    const SubmitCategory = (title,description,keywords) =>{
      toAddNew? AddNewCategory(title,description,keywords) : EditCategory(title,description,keywords)
      
    }
    const UpdateImgData = (alt,filepath) =>{
      EditCatMainImage(alt,filepath)
    }
    const EditModalConfigStatus = () =>{
      setHandlingImage(false)
    }
    const handleDeleteClick = async(categroy) =>{
      let FunctionName = ''
      switch(CategoryType){
        case 'Article':
          FunctionName = 'DeleteArticleCategory'
          break
        case 'Portfolio':
          FunctionName = 'DeletePortfolioCategory'
          break
        case 'Game':
          FunctionName = 'DeleteGameCategory'
          break
        case 'Image':
          FunctionName = 'DeleteImageCategory'
          break
        case 'Youtube':
          FunctionName = 'DeleteYoutubeCategory'
          break
        case 'Media':
          FunctionName= 'DeleteMediaCategory'
       
      }
      try {
        const res = await axios.post(API_URL, {
          query: `
            mutation {
              ${FunctionName}(id:"${categroy.id}") 
            }
          `
        }, {
          headers: {
            token: `Bearer ${getAuthentication()}`
          }
        });
  
        if(res.data.data[FunctionName].id !== null){
          setModalOpen(false)
          fetchData()
        }
      } catch (error) {
      }
    }

    const EditCatMainImage = async (alt,filepath) =>{
      let FunctionName = '';
      switch(CategoryType){
        case 'Article':
          FunctionName = 'EditArticleCategoryMainImage'
          break
        case 'Portfolio':
          FunctionName = 'EditPortfolioCategoryMainImage'
          break
        case 'Game':
          FunctionName = 'EditGameCategoryMainImage'
          break
        case 'Image':
          FunctionName = 'EditImageCategoryMainImage'
          break
        case 'Youtube':
          FunctionName = 'EditYoutubeCategoryMainImage'
          break
        case 'Media':
          FunctionName = 'EditMediaCategoryMainImage'
      
      }

      const res = await axios.post(API_URL, {
        query: `
          mutation {
            ${FunctionName}(id:"${editedCat.id}",alt:"${alt}",filepath:"${filepath}",watermark:false) {
              id
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data[FunctionName].id !== null){
        setModalOpen(false)
        setHandlingImage(false)
        fetchData()
      }
    }

    const AddNewCategory = async(title,describe,keywords) =>{

      let FunctionName = '';
    switch(CategoryType){
      case 'Article':
        FunctionName = 'addNewArticleCategory'
        break
      case 'Portfolio':
        FunctionName = 'addNewPortfolioCategory'
        break
      case 'Game':
        FunctionName = 'addNewGameCategory'
        break
      case 'Image':
        FunctionName = 'addNewImageCategory'
        break
      case 'Youtube':
        FunctionName = 'addNewYoutubeCategory'
        break
      case 'Media':
          FunctionName = 'addNewMediaCategory'
     
    }
    
    const keywordString = keywords.join('-');
    console.log(title,describe,keywordString)
    try {
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            ${FunctionName}(title:"${title}", metaDescription:"${describe}", metaKeywords:"${keywordString}") {
              id
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data[FunctionName].id !== null){
        setModalOpen(false)
        fetchData()
      }
    } catch (error) {
    }
      
    }
    const EditCategory = async(title,describe,keywords) =>{

      let FunctionName = '';
    switch(CategoryType){
      case 'Article':
        FunctionName = 'EditArticleCategory'
        break
      case 'Portfolio':
        FunctionName = 'EditPortfolioCategory'
        break
      case 'Game':
        FunctionName = 'EditGameCategory'
        break
      case 'Image':
        FunctionName = 'EditImageCategory'
        break
      case 'Youtube':
        FunctionName = 'EditYoutubeCategory'
        break
      case 'Media':
        FunctionName = 'EditMediaCategory'
    
    }
    const keywordString = keywords.join('-');
    console.log(title,describe,keywordString)
    try {
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            ${FunctionName}(id:"${editedCat.id}",title:"${title}", metaDescription:"${describe}", metaKeywords:"${keywordString}") {
              id
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data[FunctionName].id !== null){
        setModalOpen(false)
        fetchData()
      }
    } catch (error) {
    }

    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleEditClick = (categroy) =>{
      setEditedCat(categroy)
      setToAddNew(false)
      setHandlingImage(false)

      setModalOpen(true)
    }
  
    const handleNewBtnClick = () =>{
      setEditedCat({})
      setToAddNew(true)
      setHandlingImage(false)

      setModalOpen(true)

    }
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }


    const HandleImageConfig =() =>{
      setHandlingImage(true)
    }

    const handleCloseModal = () =>{
      setModalOpen(false)
      setHandlingImage(false)

    }
    const fetchData = async () => {
      setLoaderVisibility('visible')

      try{
    let FunctionName = ''
    switch(CategoryType){
      case 'Article':
        FunctionName = 'GetAllArticleCategory'
        break
      case 'Portfolio':
        FunctionName = 'GetAllPortfilioCategory'
        break
      case 'Game':
        FunctionName = 'GetAllGameCategory'
        break
      case 'Image':
        FunctionName = 'GetAllImageCategory'
        break
      case 'Youtube':
        FunctionName = 'GetAllYoutubeCategory'
      case 'Media':
          FunctionName = 'GetAllMediaCategory'
        break
    }
     const res = await axios.post(API_URL,{
          query:`query{
            ${FunctionName}{
              id
              MainInfo{
                title
              }
              seo{
                metaDescription
                metaKeywords
              }
              info{
                articleCount
              }
            }
          }`
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
      })
      if(res.status === 200){
        SetCategories(res.data.data[FunctionName])
        setLoaderVisibility('hidden')

      }
    }
    catch(err){

    }
    }

    useEffect(() => {
      fetchData()
      }, []);
  
    return (
        <Box m="20px">
            <Header title={`${CategoryType} Categories`} subtitle={`Managing ${CategoryType} Categories`}  />
            <Modal
      open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
>
  {!handlingImage ? <CategoryConfig NewCat={toAddNew} Category={editedCat} SubmitCategory={SubmitCategory} closingModal={handleCloseModal} handleImaeg={HandleImageConfig}/> : <ImageConfig Article={editedCat} UpdateImgData={UpdateImgData} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>}
 
</Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                <Box   sx={{
                display:"flex",
                visibility:isLoaderVisible,
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New {CategoryType} Category</Button>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Category Title</TableCell>
        <TableCell>Article Count</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    
    categories.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(cat => (
  <TableRow key={cat.id}>
    <TableCell>{cat.MainInfo.title}</TableCell>
    <TableCell>{cat.info.articleCount}</TableCell>
    <TableCell>
      <IconButton color="secondary" onClick={() => handleEditClick(cat)}> 
        <EditIcon />
      </IconButton>
      <IconButton color="error" onClick={() => handleDeleteClick(cat)}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
))}
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={categories.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>

        </Box>
    )
}

export default Categories