import React, { useState, useEffect } from "react";

import { Routes, Route } from "react-router-dom";
import axios from "axios";
import TopBar from "./../../global/Topbar";
import SideBar from "./../../global/SideBar";
import Dashboard from "./../../dashboard";
import Team from "./../../team";
import Invoices from "./../../invoices";
import Contacts from "./../../contacts";
import Bar from "./../../bar";
import Form from "./../../form";
import Line from "./../../line";
import Pie from "./../../pie";
import FAQ from "./../../faq";
import Editor from "./../../editor";
import { getAuthentication } from "../../../auth";

import Categories from "../../Sections/Categories";
import Article from "../../Sections/Articles";
import Portfolio from "../../Sections/Portfolio";
import Game from "../../Sections/Game";
import Poll from "../../Sections/Poll";
import { GLOBAL_API } from "../../../data/Links";
import BannerAds from "../../Sections/Banner";
import ArticleTopTags from "../../Sections/ArticleTopTags";
const API_URL = `${GLOBAL_API}/gql`;

const Main = ({ signOutHandler }) => {
  const [isUser, setIsUser] = useState(false);
  const [token, setToken] = useState("");
  const [permissions, setPermissions] = useState({
    article:false,
    portfolio:false,
    education:false,
    imageArchive:false,
    youtubeArchive:false,
    poll:false,
    ads:false,
    banner:false,
    media:false
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(
          API_URL,
          {
            query: `
              query {
                GetUserPermission {
                  id
                  permissions {
                    article
                    portfolio
                    education
                    imageArchive
                    youtubeArchive
                    poll
                    ads
                    banner
                    media
                  }
                }
              }
            `,
          },
          {
            headers: {
              token: `Bearer ${getAuthentication()}`,
            },
          }
        );
        if (res.status === 200) {
          if (res.data?.data?.GetUserPermission?.id !== null) {
            setPermissions((prevState) => ({
              ...prevState,
              article: res.data.data.GetUserPermission.permissions.article,
              portfolio: res.data.data.GetUserPermission.permissions.portfolio,
              education: res.data.data.GetUserPermission.permissions.education,
              imageArchive: res.data.data.GetUserPermission.permissions.imageArchive,
              youtubeArchive: res.data.data.GetUserPermission.permissions.youtubeArchive,
              poll: res.data.data.GetUserPermission.permissions.poll,
              ads: res.data.data.GetUserPermission.permissions.ads,
              banner:res.data.data.GetUserPermission.permissions.banner,
              media:res.data.data.GetUserPermission.permissions.media
              
            }))
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData()
  }, []);

  return (
    <>
      <SideBar Userpermissions={permissions} />
      <main className="content">
        <TopBar signOutHandler={signOutHandler} />
        
        <Routes>
           {
            permissions.article? (
              <>
                <Route path="/BannerAds" element={<BannerAds key="B1"  />} /> 
              </> 

            ):(<></>)
          }
          {
            permissions.article? (
              <>
                <Route path="/ArticleCategory" element={<Categories key="c1" CategoryType="Article" />} /> 
                <Route path="/ArticlesTopTags" element={<ArticleTopTags key="ga1" CategoryType="Article" />} /> 
                <Route path="/Articles" element={<Article key="a1" />} /> 
              </> 

            ):(<></>)
          }

          {
            permissions.portfolio? (
              <>
                <Route path="/PortfolioCategory" element={<Categories key="c2" CategoryType="Portfolio" />} /> 
                <Route path="/Portfolio" element={<Portfolio key="a2" />} /> 
              </> 

            ):(<></>)
          }

          {
            permissions.portfolio? (
              <>
                <Route path="/GameCategory" element={<Categories key="c6" CategoryType="Game" />} /> 
                <Route path="/Game" element={<Game key="k2" />} /> 
              </> 

            ):(<></>)
          }

          {
            permissions.media? (
              <>
                <Route path="/MediaCategory" element={<Categories key="c9" CategoryType="Media" />} /> 
                <Route path="/Media" element={<Game key="k2" />} /> 
              </> 

            ):(<></>)
          }


          {
            permissions.imageArchive? (
              <>
                <Route path="/ImageCategory" element={<Categories key="c3" CategoryType="Image" />} /> 
              </> 

            ):(<></>)
          }
            {
            permissions.youtubeArchive? (
              <>
                <Route path="/YoutubeCategory" element={<Categories key="c4" CategoryType="Youtube" />} /> 
              </> 

            ):(<></>)
          }
          {
            permissions.poll?(
              <>
                <Route path="/Poll" element={<Poll key="p1"/>} />
              </>
            ):(<></>)
          }
          <Route path="/" element={<Dashboard />} />
       
        </Routes>
      </main>
    </>
  );
};

export default Main;
