import { Box, Button, Divider, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup,IconButton } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { ReactTagify } from "react-tagify";
import { getAuthentication } from "../../auth";
import axios from "axios";
import InfoIcon from '@mui/icons-material/Info';
import ImageIcon from '@mui/icons-material/Image';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LanguageIcon from '@mui/icons-material/Language';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { GLOBAL_API } from "../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const GameConfig = ({Game,UpdateGameOption,EditModalConfigStatus,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [published,setPublished] = useState(Game.options.published)

 


  const handleFormSubmit = (values) => {
    console.log(values);
  };
  


  const upDatePublishState = () =>{
    setPublished(!published)
    UpdateGameOption(!published)
  }

 

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  height:"50%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
            <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>

      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Config Game</Typography>
      <Typography variant="h6" sx={{
        textAlign:"center",
        mt:1,
        mb:1
      }}>{Game.MainInfo.title}</Typography>
      <Divider />
      <Formik
        onSubmit={handleFormSubmit}
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    fullWidth
                    sx={{mt:2}} color="secondary" >
                            <Button key="ArticleMainInfoNav" onClick={()=>EditModalConfigStatus('ADD')}><InfoIcon sx={{mx:1}} /> Main Info</Button>
                            <Button key="ArticleMainImageNav" onClick={()=>EditModalConfigStatus('IMG')}><ImageIcon sx={{mx:1}} /> Main Image</Button>
                            <Button key="ArticleContentNav" onClick={()=>EditModalConfigStatus('CNT')}><FileCopyIcon sx={{mx:1}} /> Content</Button>
                            <Button key="ArticleSEONav" onClick={()=>EditModalConfigStatus('SEO')}><LanguageIcon sx={{mx:1}} /> SEO</Button>
                </ButtonGroup>
                <FormControlLabel control={<Checkbox color="secondary" checked={published}  />}  label="Publish Game" onChange={upDatePublishState} />

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default GameConfig;