import { Box,useTheme,Pagination,TableContainer,Table,Select,TextField,MenuItem,FormControl,InputLabel,TableBody,TableHead,TableCell,Paper,TableRow,TablePagination,Button,IconButton,Modal} from "@mui/material"
import { useState,useEffect } from "react";
import Header from "../../../components/Header"
import { tokens } from "../../../theme";
import axios from "axios";
import { getAuthentication } from "../../../auth";
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const ArticleTopTags = ({CategoryType}) =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [tags,setTags] = useState([])
    const [categories,setCategories] = useState([])
    const [selectedCategory,setSelectedCategory] = useState(null)
    const [tag,setTag] = useState('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [toAddNew,setToAddNew] = useState(true)
    const [editedCat,setEditedCat] = useState({})
    const [handlingImage,setHandlingImage] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')

    
   
   
    const handleDeleteClick = async(categroy) =>{
    
      try {
        const res = await axios.post(API_URL, {
          query: `
            mutation {
              DeleteArticleCategoryTag(id:"${categroy.id}") 
            }
          `
        }, {
          headers: {
            token: `Bearer ${getAuthentication()}`
          }
        });
  
        if(res.data.data.DeleteArticleCategoryTag){
          fetchData()
        }
      } catch (error) {
      }
    }

    const fetchCategories = async() => {
        try {
            const res = await axios.post(API_URL, {
              query: `
                query {
                  GetAllArticleCategory{
                    id
                    MainInfo{
                        title
                    }
                  }
                }
              `
            }, {
              headers: {
                token: `Bearer ${getAuthentication()}`
              }
            });
      
            if(res.data.data.GetAllArticleCategory){
                setCategories(res.data.data.GetAllArticleCategory)
                fetchData()
            }
          } catch (error) {
          }
    }
    
    const AddNewTag = async() =>{
     
    try {
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            AddNewArticleCategoryTag(id:"${selectedCategory}", tag:"${tag}") {
              id
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.AddNewArticleCategoryTag.id !== null){
        fetchData()
      }
    } catch (error) {
    }
      
    }
  

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleEditClick = (categroy) =>{
      setEditedCat(categroy)
      setToAddNew(false)
      setHandlingImage(false)

      setModalOpen(true)
    }
  
   
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }


    const HandleImageConfig =() =>{
      setHandlingImage(true)
    }

    const handleCloseModal = () =>{
      setModalOpen(false)
      setHandlingImage(false)

    }
    const fetchData = async () => {
      setLoaderVisibility('visible')
      try{
   
     const res = await axios.post(API_URL,{
          query:`query{
            GetArticleCategoryTags{
              id
              Category{
                    id
                    MainInfo{
                        title
                    }
              }
              Tag
              
            }
          }`
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
      })
      if(res.status === 200){
        setTags(res.data.data.GetArticleCategoryTags)
        setLoaderVisibility('hidden')

      }
    }
    catch(err){

    }
    }

    useEffect(() => {
        fetchCategories()
      fetchData()
      }, []);
  
    return (
        <Box m="20px">
            <Header title={`Articles Top Tags`} subtitle={`Managing Articles Top Tags`}  />
          
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
               
                    <Box sx={{display:"flex",flexDirection:"row",width:"80%",gap:1,justifyContent:'flex-start'}}>
                    <FormControl  sx={{width:'30%'}}>
                    <InputLabel color="secondary" id="demo-simple-select-label">Select Category ...</InputLabel>
                        <Select sx={{width:'100%'}} variant="filled"  value={selectedCategory} onChange={(e)=>setSelectedCategory(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Category ..." >
                        {categories.map((cat) => (
                            <MenuItem key={cat.id} value={cat.id}>
                                {cat.MainInfo.title}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <TextField 
                        sx={{
                            width:"30%"
                        }}
                        variant="filled"
                        type="text"
                        value={tag}
                        onChange={(e)=>setTag(e.target.value)}
                        label="Tag ..."
                        color="secondary"
                        />
                    <Button variant="contained" onClick={AddNewTag} color="secondary">Add New {CategoryType} Category</Button>

                    </Box>
                    <Box   sx={{
                display:"flex",
                visibility:isLoaderVisible,
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Tag </TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    
    tags.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(cat => (
  <TableRow key={cat.id}>
    <TableCell>{cat.Tag}</TableCell>
    <TableCell>{cat.Category.MainInfo.title}</TableCell>
    <TableCell>
      
      <IconButton color="error" onClick={() => handleDeleteClick(cat)}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
))}
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={tags.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>

        </Box>
    )
}

export default ArticleTopTags