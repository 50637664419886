import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,Paper,TableRow,TablePagination,Button,IconButton,Modal} from "@mui/material"
import { useState,useEffect } from "react";
import Header from "../../../components/Header"
import { tokens } from "../../../theme";
import axios from "axios";
import { getAuthentication } from "../../../auth";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddNewPOLL from "../../../components/Poll/AddNewPoll";
import { GLOBAL_API } from "../../../data/Links";
const API_URL = `${GLOBAL_API}/gql`;

const PollGQLSchema = `
id
Question
isActive
Options{
  id
  text
  votes{
    name
    email
    date
  }
  votes_nr
}
infos{
  createdAt
  updateDate
}
`
const Poll = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [polls,setPolls] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [selectedPoll,setselectedPoll] = useState({})
    const [isNewPoll,setIsNewPoll] = useState(false)


    const HandleSubmit = (Question) =>{
      isNewPoll ? AddNewPoll(Question) : AddNewOption(Question) 
      }
      const PublishPoll =() =>{
        PublishPollHandle()
      }
    
    const PullOption = (id) =>{
      PullOptionHandle(id)
    }
    const handleCloseModal = () =>{
      setModalOpen(false)
    }
    const ModalComponents = {
        "ADD": <AddNewPOLL NewPOLL={isNewPoll} Poll={selectedPoll} PublishPoll={PublishPoll}  HandleSubmit={HandleSubmit} PullOption={PullOption} closingModal={handleCloseModal} />,
       
    }

  
  

    const PullOptionHandle = async(id) =>{
      const res = await axios.post(API_URL,{
        query:`mutation{
          PullOptionPoll(id:"${selectedPoll.id}",o_id:"${id}"){
            ${PollGQLSchema}
          }
        }`
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      })

      if(res.data.data.PullOptionPoll.id !== null){
        setselectedPoll(res.data.data.PullOptionPoll)
        setIsNewPoll(false)
        setModalStatus("ADD")
        fetchData()
      }

    }

    const PublishPollHandle = async() =>{
      const res = await axios.post(API_URL,{
        query:`mutation{
          PublishPoll(id:"${selectedPoll.id}"){
            ${PollGQLSchema}
          }
        }`
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      })
      if(res.data.data.PublishPoll.id !== null){
        setselectedPoll(res.data.data.PublishPoll)
        setIsNewPoll(false)
        setModalStatus("ADD")
        fetchData()
      }

    } 

   
 
  

    const AddNewOption = async (option) =>{
      const res = await axios.post(API_URL,{
        query:`mutation{
          PushOptionPoll(id:"${selectedPoll.id}",text:${JSON.stringify(option)}){
            ${PollGQLSchema}
          }
        }`
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      })
      if(res.data.data.PushOptionPoll.id !== null){
        setselectedPoll(res.data.data.PushOptionPoll)
        setIsNewPoll(false)
        setModalStatus("ADD")
        fetchData()
      }

    }
    
  
    const AddNewPoll = async(Question) => {
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            AddNewPoll(question:"${Question}") {
              ${PollGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.AddNewPoll.id !== null){
        setselectedPoll(res.data.data.AddNewPoll)
        setIsNewPoll(false)
        setModalStatus("ADD")
        fetchData()
      }
    }
    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleEditClick = (poll) =>{
      setselectedPoll(poll)
      setModalStatus("ADD")
      setIsNewPoll(false)
      setModalOpen(true)
    }

  
    const handleNewBtnClick = () =>{
    setIsNewPoll(true)
     setModalOpen(true)
      setModalStatus('ADD')
    }
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }

    const fetchData = async () => {
      try{
   
     const res = await axios.post(API_URL,{
          query:`query{
            GetAllPolls{
              ${PollGQLSchema}
            }
          }`
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
      })
      if(res.status === 200){
        setPolls(res.data.data.GetAllPolls)
      }
    }
    catch(err){

    }
    }

    useEffect(() => {
      fetchData()
      }, []);
  
    return (
        <Box m="20px">
            <Header title={`Polls`} subtitle={`Managing Polls`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                 
                    {ModalComponents[modalStatus]}

                  </>
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"flex-end"
            }}>
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Poll</Button>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Poll Question</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Votes</TableCell>
        <TableCell>published</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    
    polls.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(pll => (
        <TableRow key={pll.id}>
        <TableCell>{pll.Question}</TableCell>
        <TableCell>{pll.infos.createdAt}</TableCell>
        <TableCell>  
          <IconButton>
                <Typography sx={{mx:1}}>{pll.Options.reduce((accumulator, option) => accumulator + option.votes_nr,0)}</Typography>
            </IconButton></TableCell>
        <TableCell>{pll.isActive? (<DoneIcon />) : (<CloseIcon />)}</TableCell>
     
        <TableCell>
        <IconButton color="secondary" onClick={() => handleEditClick(pll)}> 
        <EditIcon />
      </IconButton>
      
      
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={polls.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>

        </Box>
    )
}

export default Poll