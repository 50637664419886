import { Box, Button, Divider,IconButton, TextField,useTheme,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TableRow,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useState } from "react";
const GameContentManagerConfig = ({Game,PullContent,EditModalConfigStatus,closingModal,EditContent}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const HandlePull = (id) =>{
    PullContent(id)
  }

  
  const handleEditBtn =(prtf) =>{
    EditModalConfigStatus("CNTEDT")
    EditContent(prtf,false)
  }

  const handleAddNew = () =>{
    EditModalConfigStatus("CNTEDT")
    EditContent({},true)
  }
  
  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>

    <IconButton onClick={()=>EditModalConfigStatus("CONFIG")}  >
        <ArrowBackIcon />
    </IconButton>
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Game Contents</Typography>
      <Typography variant="h6" sx={{
        textAlign:"center",
        mt:1,
        mb:1
      }}>{Game.MainInfo.title}</Typography>
      <Divider />
      <Button key="ArticleMainInfoNav" onClick={handleAddNew} sx={{mt:1,width:"100%"}} variant="contained" color="secondary" >Add New Content</Button>

      <TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Content Title</TableCell>
        <TableCell>Action</TableCell>
      
      </TableRow>
    </TableHead>
    <TableBody>
        {
            Game.content.map(prtf=>(
                <TableRow key={prtf.id}>
                    <TableCell>{prtf.title}</TableCell>
                    <TableCell><IconButton  color="secondary" onClick={() => handleEditBtn(prtf)}><EditIcon /></IconButton><IconButton  color="error" onClick={() => HandlePull(prtf.id)}><DeleteIcon /></IconButton></TableCell>
                </TableRow>
            ))
        }
    </TableBody>
    
    </Table>
    </TableContainer>
    
    </Box>
  );
};
export default GameContentManagerConfig;