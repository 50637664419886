import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel } from "@mui/material"
import { useState,useEffect } from "react";
import Header from "../../../components/Header"
import { tokens } from "../../../theme";
import axios from "axios";
import { getAuthentication } from "../../../auth";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArticleMainInfo from "../../../components/Article/AddNewArticleConfig";
import ArticleConfig from "../../../components/Article/ArticleConfig";
import ImageConfig from '../../../components/Article/ArticleImageConfig';
import ArticleContent from "../../../components/Article/ArticleContentConfig";
import ArticleSEO from "../../../components/Article/ArticleSEOConfig";
import AddAttachArtConfig from "../../../components/Article/AddAttacmentArticleConfig";
import ArticleAttachment from "../../../components/Article/ArticleAttachmentsConfig";
import ArticleSearchConfig from "../../../components/Article/ArticleSearchConfig";
import CircularProgress from '@mui/material/CircularProgress';
import LaunchIcon from '@mui/icons-material/Launch';
import SearchIcon from '@mui/icons-material/Search';
import { GLOBAL_API } from "../../../data/Links";
const API_URL = `${GLOBAL_API}/gql`;

const ArticleGQLSchema = `id
MainInfo{
  title
  slug
  author{
      exist
      author_name
  }
  category{
      id
      MainInfo{
          title
          slug
      }
  }
  source{
      exist
      name
      link
  }
}
MainImage{
  alt
  filepath
}
content
seo{
  description
  keywords
}
Attachments{
  id
  type
  content
  alt
}
options{
  onHeader
  published
}
infos{
  views
  createdAt
}`
const Article = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [articles,setArticles] = useState([])
    const [filteredData,setFilteredData] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [selectedArticle,setSelectedArticle] = useState({})
    const [isNewArticle,setIsNewArticle] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')
    const [FilterOptions, setFilterOptions] = useState([]);
    const [FilterValue, setFilterValue] = useState([]);
    const [publishStatment,setPublishStatement] = useState('all')
    const [articleCount,setArticleCount] = useState(0)


    const HandleMainInfoSubmit = (title,isAuthor,authorName,category,isSource,sourceName,sourceLink) =>{
      isNewArticle ? AddNewArticle(title,isAuthor,authorName,category,isSource,sourceName,sourceLink) : EditMainInfo(title,isAuthor,authorName,category,isSource,sourceName,sourceLink)
    }
    const HandleUpdateArticleOption = (header,published) =>{
      UpdateArticleOption(header,published)
    }
    const UpdateImgData = (alt,filepath) =>{
      EditArticleMainImage(alt,filepath)
    }
    const UpdateContentData = (content)=>{
      EditArticleContent(content)
    }
    const UpdateSEOtData = (keywords,description) =>{
      EditArticleSEO(keywords,description)
    }
    const AddAttachToArt = (type,content,alt)=>{
      ArticlePushAttach(type,content,alt)
    }
    const PullAttachement = (id) =>
    {
      ArticlePullAttach(id)
    }
    const EditModalConfigStatus = (state) =>{
      setModalStatus(state)
    }
    const handleCloseModal = () =>{
      setModalOpen(false)
    }

    const editFunction = (article) =>{
      handleEditClick(article)
    }

    const dltFunction = async(article) =>{
      handleDeleteClick(article)
    }
    const ModalComponents = {
        "ADD": <ArticleMainInfo NewArt={isNewArticle} Article={selectedArticle}  HandleMainInfoSubmit={HandleMainInfoSubmit} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal} />,
        "CONFIG": <ArticleConfig Article={selectedArticle} UpdateArticleOption={HandleUpdateArticleOption} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "IMG": <ImageConfig Article={selectedArticle} UpdateImgData={UpdateImgData} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "CNT":<ArticleContent Article={selectedArticle} UpdateContentData={UpdateContentData} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "SEO":<ArticleSEO Article={selectedArticle} UpdateSEOtData={UpdateSEOtData} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "ADDATCH":<AddAttachArtConfig Article={selectedArticle}  AddAttachToArt={AddAttachToArt} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "ATCH":<ArticleAttachment Article={selectedArticle}  PullAttachement={PullAttachement} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "SEARCH":<ArticleSearchConfig articleSchema={ArticleGQLSchema} editFunction={editFunction} dltFunction={dltFunction} closingModal={handleCloseModal}/>
    }

  
    
    const ArticlePullAttach = async(id) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            ArticlePullAttach(id:"${selectedArticle.id}",attach_id:"${id}") {
              ${ArticleGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.ArticlePullAttach.id !== null){
        setSelectedArticle(res.data.data.ArticlePullAttach)
        setModalStatus("ATCH")
        fetchData()
      }
    }

    
    const ArticlePushAttach = async(type,content,alt) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            ArticlePushAttach(id:"${selectedArticle.id}",attach_type:"${type}",attach_content:"${content}",alt:"${alt}") {
              ${ArticleGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.ArticlePushAttach.id !== null){
        setSelectedArticle(res.data.data.ArticlePushAttach)
        setModalStatus("CONFIG")
        fetchData()
      }
    }

    const EditArticleSEO = async (keywords,description) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditArticleSEO(id:"${selectedArticle.id}",keywrods:"${keywords.join('-')}",description:"${description}") {
              ${ArticleGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditArticleSEO.id !== null){
        setSelectedArticle(res.data.data.EditArticleSEO)
        setModalStatus("CONFIG")
        fetchData()
      }
    }
    const EditArticleContent = async(content) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditeArticleContent(id:"${selectedArticle.id}",content:${JSON.stringify(content)}) {
              ${ArticleGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditeArticleContent.id !== null){
        setSelectedArticle(res.data.data.EditeArticleContent)
        setModalStatus("CONFIG")
        fetchData()
      }

    }
    const EditArticleMainImage = async(alt,filepath) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditArticleMainImage(id:"${selectedArticle.id}",alt:"${alt}",filepath:"${filepath}",watermark:false) {
              ${ArticleGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditArticleMainImage.id !== null){
        setSelectedArticle(res.data.data.EditArticleMainImage)
        setModalStatus("CONFIG")
        fetchData()
      }

    }   
    const UpdateArticleOption = async (header,published) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditArticleOptions(id:"${selectedArticle.id}",onHeader:${header},published:${published}) {
              ${ArticleGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditArticleOptions.id !== null){
        setSelectedArticle(res.data.data.EditArticleOptions)
        setModalStatus("CONFIG")
        fetchData()
      }
    }

   
    
    const EditMainInfo = async(title,isAuthor,authorName,category,isSource,sourceName,sourceLink) =>{

      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditArticleMainInfo(id:"${selectedArticle.id}",title:${JSON.stringify(title)},author_exist:${isAuthor},author_name:"${authorName}",category:"${category}",source_exist:${isSource},source_name:"${sourceName}",source_link:"${sourceLink}") {
              ${ArticleGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditArticleMainInfo.id !== null){
        setSelectedArticle(res.data.data.EditArticleMainInfo)
        setIsNewArticle(false)
        setModalStatus("CONFIG")
        fetchData()
      }


    }
    const AddNewArticle = async(title,isAuthor,authorName,category,isSource,sourceName,sourceLink) => {
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            AddNewArticle(title:${JSON.stringify(title)},author_exist:${isAuthor},author_name:"${authorName}",category:"${category}",source_exist:${isSource},source_name:"${sourceName}",source_link:"${sourceLink}") {
              ${ArticleGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.AddNewArticle.id !== null){
        setSelectedArticle(res.data.data.AddNewArticle)
        setIsNewArticle(false)
        setModalStatus("CONFIG")
        fetchData()
      }
    }
    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handlesSearchBtnClick = () =>{
      setModalStatus("SEARCH")
      setModalOpen(true)
    }

    const handleEditClick = (article) =>{
      setSelectedArticle(article)
      setModalStatus("CONFIG")
      setIsNewArticle(false)
      setModalOpen(true)
    }

    const PreviewOnWeb = (obj) =>{
      window.open('https://time4.news/Article/'+obj.MainInfo.slug, '_blank');
    }

    const handleDeleteClick = async(article) =>{
      try {
         await axios.post(API_URL, {
          query: `
            mutation {
              DeleteArticle(id:"${article.id}") 
            }
          `
        }, {
          headers: {
            token: `Bearer ${getAuthentication()}`
          }
        });

        fetchData()

      } catch (error) {
      }
    }
  
    const handleNewBtnClick = () =>{
    setIsNewArticle(true)
     setModalOpen(true)
      setModalStatus('ADD')

    }
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }

    const fetchData = async () => {
      setLoaderVisibility('flex')
      try{
        const res = await axios.post(API_URL,{
              query:`query{
                GetAllArticles(count:${rowsPerPage},offset:${page},sort:"-1"){
                  ${ArticleGQLSchema}
                }
              }`
          },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
          })
          if(res.status === 200){
            setArticles(res.data.data.GetAllArticles)
            setLoaderVisibility('none')
          }
      }
      catch(err){}
    }

    const getCounter = async(key) =>{
        const res = await axios.post(API_URL,{
            query:`query{
              GetCounter(key:"${key}"){
                value
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setArticleCount(res.data.data.GetCounter.value)
          setLoaderVisibility('none')
        }
    }

    useEffect(() => {
      fetchData()
      getCounter('articles')
      let arr_title = []
        articles.map(art=>arr_title.push(art.MainInfo.title))
        setFilterOptions(arr_title)
      }, [rowsPerPage,page]);


      const HandleFilterChange = (value) =>{
        setFilterValue(value)
        if(value.length>0){
           const fileted_data =  articles.filter(art=>art.MainInfo.title.includes(value))
           setFilteredData(fileted_data)
        }
        else{
          setFilteredData([])
        }
      }
  
    return (
        <Box m="20px">
            <Header title={`Articles`} subtitle={`Managing Articles`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                 
                    {ModalComponents[modalStatus]}

                  </>
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                    {
                      isLoaderVisible === 'none'? (<Button variant="contained" onClick={handlesSearchBtnClick} color="secondary"><SearchIcon /></Button>):(<></>)
                    }
                    

                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'none' : 'flex',
                justifyContent:"flex-start",
                alignContent:"flex-start",
                alignItems:"flex-start",
                width: '70%',
            }}>
                       
                    </Box>
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Article</Button>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Article Title</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Views</TableCell>
        <TableCell>published</TableCell>
        <TableCell>Preview</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
 
    articles.map(art => (
        <TableRow key={art.id}>
        <TableCell>{art.MainInfo.title}</TableCell>
        <TableCell>{art.MainInfo.category.MainInfo.title}</TableCell>
        <TableCell>{new Date(art.infos.createdAt).toLocaleString()}</TableCell>
        <TableCell>  
          <IconButton>
                <Typography sx={{mx:1}}>{art.infos.views / 2}</Typography>
                <VisibilityIcon />
            </IconButton></TableCell>
        <TableCell>{art.options.published? (<DoneIcon />) : (<CloseIcon />)}</TableCell>
        <TableCell>
        <IconButton>
        <IconButton  onClick={()=>PreviewOnWeb(art)}> 
        <LaunchIcon  />
        </IconButton>
        </IconButton>
        </TableCell>
        <TableCell>
        <IconButton color="secondary" onClick={() => handleEditClick(art)}> 
        <EditIcon />
      </IconButton>
      <IconButton color="error" onClick={() => handleDeleteClick(art)}>
        <DeleteIcon />
      </IconButton>
      
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={articleCount}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>

        </Box>
    )
}

export default Article