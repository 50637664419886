import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,Paper,TableRow,TablePagination,Button,IconButton,Modal} from "@mui/material"
import { useState,useEffect } from "react";
import Header from "../../../components/Header"
import { tokens } from "../../../theme";
import axios from "axios";
import { getAuthentication } from "../../../auth";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddNewGameConfig from "../../../components/Portfolio/AddNewGameConfig";
import GameConfig from "../../../components/Portfolio/GameConfig";
import GameImageConfig from "../../../components/Portfolio/GameImageConfig";
import GameSEO from "../../../components/Portfolio/GameSEOConfig";
import GameContentManagerConfig from "../../../components/Portfolio/GameContentManagerConfig";
import GameContentConfig from "../../../components/Portfolio/PortfolioContentConfig";
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const GameGQLSchema = `id
MainInfo{
  title
  names{
    ar
    en
  }
  startDate{
    isBirth
    date
  }
  endDate{
    isDeath
    date
  }
  startPlace{
    isBirth
    place
  }
  owners
  category{
      id
      MainInfo{
          title
          slug
      }
  }
  author{
    exist
    author_name
  }
  source{
      exist
      name
      link
  }
}
MainImage{
  alt
  filepath
}
content{
    id 
    title
    text
}
seo{
  description
  keywords
}

options{
  published
  done
}
infos{
  views
  createdAt
}`
const Game = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [Games,setGame] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [selectedGame,setselectedGame] = useState({})
    const [selectedContent,setselectedContent] = useState({})
    const [isNewContentAdder,setIsNewContentAdder] = useState(true)
    const [isNewGame,setIsNewGame] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')


    const HandleMainInfoSubmit = (title,ar,en,isAuthor,authorName,category,isSource,sourceName,sourceLink,isBirth,endDate,startPlace,startDateValue,endDateValue,startPlaceValue,owners) =>{
      isNewGame ? AddNewGame(title,ar,en,isAuthor,authorName,category,isSource,sourceName,sourceLink,isBirth,endDate,startPlace,startDateValue,endDateValue,startPlaceValue,owners) : EditMainInfo(title,ar,en,isAuthor,authorName,category,isSource,sourceName,sourceLink,isBirth,endDate,startPlace,startDateValue,endDateValue,startPlaceValue,owners)
    }
    const HandleUpdateGameOption = (published) =>{
      UpdateGameOption(published)
    }
    const UpdateImgData = (alt,filepath) =>{
      EditGameMainImage(alt,filepath)
    }
    const HandleContentSubmit = (title,content) =>{
      selectedContent.id? EditContent(title,content): PushNewContent(title,content)
    }
    const PrepareContentToBeEdited = (content,state)=>{
      setIsNewContentAdder(state)
      setselectedContent(content)
      console.log(state)
    }
    const PullContent =(id)=>{
      PullGameContent(id)
    } 
    const UpdateSEOtData = (keywords,description) =>{
      EditGameSEO(keywords,description)
    }
   
    const EditModalConfigStatus = (state) =>{
      setModalStatus(state)
    }
    const handleCloseModal = () =>{
      setModalOpen(false)
    }
    const ModalComponents = {
        "ADD": <AddNewGameConfig NewArt={isNewGame} Game={selectedGame}  HandleMainInfoSubmit={HandleMainInfoSubmit} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal} />,
        "CONFIG": <GameConfig Game={selectedGame} UpdateGameOption={HandleUpdateGameOption} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "IMG": <GameImageConfig Game={selectedGame} UpdateImgData={UpdateImgData} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "CNT":<GameContentManagerConfig Game={selectedGame} EditContent={PrepareContentToBeEdited} PullContent={PullContent} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "SEO":<GameSEO Game={selectedGame} UpdateSEOtData={UpdateSEOtData} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
        "CNTEDT":<GameContentConfig Content={!isNewContentAdder?selectedContent:{}} UpdateContentData={HandleContentSubmit} EditModalConfigStatus={EditModalConfigStatus} closingModal={handleCloseModal}/>,
    }
    const EditGameSEO = async (keywords,description) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditGameSEO(id:"${selectedGame.id}",keywrods:"${keywords.join('-')}",description:"${description}") {
              ${GameGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditGameSEO.id !== null){
        setselectedGame(res.data.data.EditGameSEO)
        setModalStatus("CONFIG")
        fetchData()
      }
    }
    
    const PushNewContent = async(title,content) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            PushGameContent(id:"${selectedGame.id}",title:"${title}",text:${JSON.stringify(content)}) {
              ${GameGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.PushGameContent.id !== null){
        setselectedGame(res.data.data.PushGameContent)
        setModalStatus("CONFIG")
        fetchData()
      }
    }


    const PullGameContent = async(id) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            PullGameContent(id:"${selectedGame.id}",cnt_id:"${id}") {
              ${GameGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.PullGameContent.id !== null){
        setselectedGame(res.data.data.PullGameContent)
        setModalStatus("CONFIG")
        fetchData()
      }
    }

    const EditContent = async(title,content) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditGameContent(id:"${selectedGame.id}",cnt_id:"${selectedContent.id}",title:"${title}",text:${JSON.stringify(content)}) {
              ${GameGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditGameContent.id !== null){
        setselectedGame(res.data.data.EditGameContent)
        setModalStatus("CONFIG")
        fetchData()
      }
    }

    const EditGameMainImage = async(alt,filepath) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditGameMainImage(id:"${selectedGame.id}",alt:"${alt}",filepath:"${filepath}",watermark:false) {
              ${GameGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditGameMainImage.id !== null){
        setselectedGame(res.data.data.EditGameMainImage)
        setModalStatus("CONFIG")
        fetchData()
      }

    }   
    const UpdateGameOption = async (published) =>{
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditPublishGame(id:"${selectedGame.id}",publish:${published}) {
              ${GameGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditPublishGame.id !== null){
        setselectedGame(res.data.data.EditPublishGame)
        setModalStatus("CONFIG")
        fetchData()
      }
    }

   
    
    const EditMainInfo = async(title,ar,en,isAuthor,authorName,category,isSource,sourceName,sourceLink,isBirth,endDate,startPlace,startDateValue,endDateValue,startPlaceValue,owners) =>{
      const ownersString = owners.join('-')
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            EditGameMainInfo(id:"${selectedGame.id}",title:"${title}",ar:"${ar}",en:"${en}",isBirth:${isBirth},startDate:"${startDateValue}",isDeath:${endDate},endDate:"${endDateValue}",isBirthPlace:${startPlace},startPlace:"${startPlaceValue}",autExist:${isAuthor},autName:"${authorName}",category:"${category}",srcExist:${isSource},srcName:"${sourceName}",srcLink:"${sourceLink}",owners:"${ownersString}") {
              ${GameGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.EditGameMainInfo.id !== null){
        setselectedGame(res.data.data.EditGameMainInfo)
        setIsNewGame(false)
        setModalStatus("CONFIG")
        fetchData()
      }


    }

    
    const AddNewGame = async(title,ar,en,isAuthor,authorName,category,isSource,sourceName,sourceLink,isBirth,endDate,startPlace,startDateValue,endDateValue,startPlaceValue,owners) => {
      const ownersString = owners.join('-')
      const res = await axios.post(API_URL, {
        query: `
          mutation {
            AddNewGame(title:"${title}",ar:"${ar}",en:"${en}",isBirth:${isBirth},startDate:"${startDateValue}",isDeath:${endDate},endDate:"${endDateValue}",isBirthPlace:${startPlace},startPlace:"${startPlaceValue}",autExist:${isAuthor},autName:"${authorName}",category:"${category}",srcExist:${isSource},srcName:"${sourceName}",srcLink:"${sourceLink}",owners:"${ownersString}") {
              ${GameGQLSchema}
            }
          }
        `
      }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
      });

      if(res.data.data.AddNewGame.id !== null){
        setselectedGame(res.data.data.AddNewGame)
        setIsNewGame(false)
        setModalStatus("CONFIG")
        fetchData()
      }
    }
    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleEditClick = (article) =>{
      setselectedGame(article)
      setModalStatus("CONFIG")
      setIsNewGame(false)
      setModalOpen(true)
    }

    const handleDeleteClick = async(prtf) =>{
      try {
         await axios.post(API_URL, {
          query: `
            mutation {
              DeleteGame(id:"${prtf.id}") 
            }
          `
        }, {
          headers: {
            token: `Bearer ${getAuthentication()}`
          }
        });

        fetchData()

      } catch (error) {
      }
    }
  
    const handleNewBtnClick = () =>{
        setIsNewGame(true)
        setModalOpen(true)
        setModalStatus('ADD')

    }
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }

    const fetchData = async () => {
      setLoaderVisibility('visible')

      try{
   
     const res = await axios.post(API_URL,{
          query:`query{
            GetAllGame(sort:"-1"){
              ${GameGQLSchema}
            }
          }`
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
      })
      if(res.status === 200){
        setGame(res.data.data.GetAllGame)
        setLoaderVisibility('hidden')

      }
    }
    catch(err){

    }
    }

    useEffect(() => {
      fetchData()
      }, []);
  
    return (
        <Box m="20px">
            <Header title={`Game`} subtitle={`Managing Games`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                 
                    {ModalComponents[modalStatus]}

                  </>
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
              <Box   sx={{
                display:"flex",
                visibility:isLoaderVisible,
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Game</Button>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Game Title</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Views</TableCell>
        <TableCell>published</TableCell>
        <TableCell>Preview</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    
    Games.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(art => (
        <TableRow key={art.id}>
        <TableCell>{art.MainInfo.title}</TableCell>
        <TableCell>{art.MainInfo.category.MainInfo.title}</TableCell>
        <TableCell>{new Date(art.infos.createdAt).toLocaleString()}</TableCell>
        <TableCell> <IconButton>
                <Typography sx={{mx:1}}>{art.infos.views}</Typography>
                <VisibilityIcon />
            </IconButton>
</TableCell>
        <TableCell>{art.options.published? (<DoneIcon />) : (<CloseIcon />)}</TableCell>
        <TableCell>
        <LaunchIcon />
           
        </TableCell>
        <TableCell>
        <IconButton color="secondary" onClick={() => handleEditClick(art)}> 
        <EditIcon />
      </IconButton>
      <IconButton color="error" onClick={() => handleDeleteClick(art)}>
        <DeleteIcon />
      </IconButton>
      
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={Games.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>

        </Box>
    )
}

export default Game