import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';

import JoditEditor from 'jodit-react';
import { GLOBAL_API } from "../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const ArticleSEO = ({Article,UpdateSEOtData,EditModalConfigStatus,closingModal}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 
  const tagifyRef = useRef()
  const [tags,setTags] = useState(Article.seo?.keywords?Article.seo.keywords:[])
  const [description,SetDescription] = useState(Article.seo?.description?Article.seo.description:"")

  const [value, setValue] = useState('')
    const editor = useRef(null)
    const [content, setContent] = useState(Article.content?Article.content:'')
   

    const HandleSubmitBtn = () =>{
        UpdateSEOtData(tags,description)
    }
  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
        <IconButton onClick={()=>EditModalConfigStatus("CONFIG")}  >
        <ArrowBackIcon />
    </IconButton>
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Article SEO</Typography>
      <Typography variant="h6" sx={{
        textAlign:"center",
        mt:1,
        mb:1
      }}>{Article.MainInfo.title}</Typography>
      <Divider />
      <Tags  defaultValue={tags} tagifyRef={tagifyRef} color="secondary" settings={{
        placeholder:"Seo Keywords ..."
      }} onChange={(e) => setTags(e.detail.tagify.value.map((tag) => tag.value))} />
      <TextField
fullWidth
          id="filled-multiline-static"
          label="Seo Description ..."
          multiline
          rows={4}
          sx={{ gridColumn: "span 2",mt:3 }}
          onChange={(e)=>SetDescription(e.target.value)}
          defaultValue={description}
          variant="filled"
          color="secondary"

        />
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Update Article SEO</Button>
    </Box>
  );
};
export default ArticleSEO;