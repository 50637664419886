import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Tags from "@yaireo/tagify/dist/react.tagify" 
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { ReactTagify } from "react-tagify";
import { getAuthentication } from "../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import { GLOBAL_API } from "../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const AddNewArticle = ({NewArt,Article,HandleMainInfoSubmit,EditModalConfigStatus,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [title,setTtiel] = useState(NewArt?'':Article.MainInfo.title)
  const [isAuthor,setIsAuthor] = useState(NewArt?false:Article.MainInfo.author.exist)
  const [authorName,setAuthorName] = useState(NewArt?false:Article.MainInfo.author.author_name)
  const [category,setCategory] = useState(NewArt?'':Article.MainInfo.category.id)
  const [isSource,setIsSource] = useState(NewArt?false:Article.MainInfo.source.exist)
  const [sourceName,setSourceName] = useState(NewArt?'':Article.MainInfo.source.name)
  const [sourceLink,setSourceLink] = useState(NewArt?'':Article.MainInfo.source.link)

  const [categoriesList,setCategoriesList] = useState([])

  const handleFormSubmit = (values) => {
    console.log(values);
  };
  
  const HandleSubmitBtn = () =>{
    HandleMainInfoSubmit(title,isAuthor,authorName,category,isSource,sourceName,sourceLink)
  }

  const fetchData = async () => {
    try{
 
   const res = await axios.post(API_URL,{
        query:`query{
          GetAllArticleCategory{
            id
            MainInfo{
              title
            }
            seo{
              metaDescription
              metaKeywords
            }
            info{
              articleCount
            }
          }
        }`
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.status === 200){
      setCategoriesList(res.data.data.GetAllArticleCategory)
    }
  }
  catch(err){

  }
  }

  useEffect(() => {
    fetchData()
    }, [])

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      {
        !NewArt? (<IconButton onClick={()=>EditModalConfigStatus("CONFIG")}  >
        <ArrowBackIcon />
    </IconButton>) : (<></>)
      }
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewArt ? "Add New Article" : "Edit Article"}</Typography>
      {!NewArt ?(<Typography variant="h6" sx={{
        textAlign:"center"
      }}>{Article.MainInfo.title}</Typography>):(<></>) }
      <Formik
        onSubmit={handleFormSubmit}
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
            <TextField 
              sx={{
                mt:3
              }}
              fullWidth 
              variant="filled"
              type="text"
              value={title}
              onChange={(e)=>setTtiel(e.target.value)}
              label="Article Title ..."
              color="secondary"
            />
            <FormControl fullWidth sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel color="secondary" id="demo-simple-select-label">Select Category ...</InputLabel>
                <Select variant="filled"  value={category} onChange={(e)=>setCategory(e.target.value)}   labelId="demo-simple-select-label"  color="secondary" fullWidth label="Select Category ..." >
                  {categoriesList.map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.MainInfo.title}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl  fullWidth sx={{ mt: 2, minWidth: 120 }}>
                <FormControlLabel control={<Checkbox color="secondary" checked={isAuthor}  />}  label="Author" onChange={()=>setIsAuthor(!isAuthor)} />
                <TextField disabled={!isAuthor} value={authorName} onChange={(e)=>setAuthorName(e.target.value)}  variant="filled" type="text" label="Author Name ..." color="secondary" />
              </FormControl>
                <FormControl  fullWidth sx={{ mt: 2, minWidth: 120 }}>
                  <FormControlLabel control={<Checkbox color="secondary" checked={isSource}  />}  label="Source" onChange={()=>setIsSource(!isSource)} />
                  <Box sx={{
                    display:"flex",
                    justifyContent:"space-between"
                  }}>
                      <TextField disabled={!isSource} value={sourceName}  onChange={(e)=>setSourceName(e.target.value)} sx={{width:"48%"}}  variant="filled" type="text" label="Source Name ..." color="secondary" />
                      <TextField disabled={!isSource} value={sourceLink}  onChange={(e)=>setSourceLink(e.target.value)} sx={{width:"48%"}}  variant="filled" type="text" label="Source Link ..." color="secondary" />
                  </Box>
              </FormControl>  
            <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewArt? `Add New Article` : `Edit Category`}</Button>

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewArticle;