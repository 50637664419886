import { Box, Button, Divider, TextField,useTheme,Typography,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup,TablePagination,TableCell,TableContainer,Table,TableRow,TableBody,TableHead,IconButton } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../auth";
import axios from "axios";

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LaunchIcon from '@mui/icons-material/Launch';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { GLOBAL_API } from "../../data/Links";

const API_URL = `${GLOBAL_API}/gql`;

const PortfolioSearchConfig = ({portfolioSchema,editFunction,dltFunction,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [portfolios,setPortfolios] = useState([])
  const [searchText,setSearchText] = useState('')
  const [isLoaderVisible,setLoaderVisibility] = useState(false)

  const PreviewOnWeb = (obj) =>{
    window.open('https://goat337.com/Portfolio/'+obj.MainInfo.slug, '_blank');
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0)
  }


  const handleFormSubmit = (values) => {
    console.log(values);
  };
  
  const handleEditClick = (portfolio) =>{
    editFunction(portfolio)
  }

  const handleDeleteClick = (portfolio) =>{
    dltFunction(portfolio).then(()=>{
        fetchData(searchText)
    })
  }
 
  const submitSearch = async() =>{
    fetchData(searchText)
  }

  const fetchData = async (text) => {
    setLoaderVisibility(true)
    try{
      const res = await axios.post(API_URL,{
            query:`query{
                SearchInPortfolio(text:"${text}"){
                ${portfolioSchema}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setPortfolios(res.data.data.SearchInPortfolio)
          setLoaderVisibility(false)
        }
    }
    catch(err){}
  }

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  height:"90%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
            <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>

      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>Search In Portfolios</Typography>
        <br/>
      <Divider />
      <Formik
        onSubmit={handleFormSubmit}
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            height:'100%'
        }}>
        <TextField
                
                id="filled-multiline-static"
                label="Search ..."
                multiline
                sx={{ gridColumn: "span 2",mt:2,mb:2,width:'35%' }}
                onChange={(e)=>setSearchText(e.target.value)}
                defaultValue={searchText}
                variant="filled"
                color="secondary"/>
             <Button disabled={isLoaderVisible} variant="contained" sx={{mx:2,height:'100%'}} onClick={submitSearch} color="secondary"> {isLoaderVisible? (<CircularProgress color="primary"  sx={{mx:2}}/>): (<SearchIcon />)}   </Button>

        </Box>
           
             <TableContainer  >

  <Table>
    <TableHead>
    <TableRow >
        <TableCell>Portfolio Title</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Views</TableCell>
        <TableCell>published</TableCell>
        <TableCell>Preview</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
 
 portfolios.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(art => (
    <TableRow key={art.id}>
    <TableCell>{art.MainInfo.title}</TableCell>
    <TableCell>{art.MainInfo.category.MainInfo.title}</TableCell>
    <TableCell>{art.infos.createdAt}</TableCell>
    <TableCell> <IconButton>
            <Typography sx={{mx:1}}>{art.infos.views}</Typography>
            <VisibilityIcon />
        </IconButton>
</TableCell>
    <TableCell>{art.options.published? (<DoneIcon />) : (<CloseIcon />)}</TableCell>
    <TableCell>
        <IconButton  onClick={()=>PreviewOnWeb(art)}> 
    <LaunchIcon  />
    </IconButton>

    </TableCell>
    <TableCell>
    <IconButton color="secondary" onClick={() => handleEditClick(art)}> 
    <EditIcon />
  </IconButton>
  <IconButton color="error" onClick={() => handleDeleteClick(art)}>
    <DeleteIcon />
  </IconButton>
  
    </TableCell>
  </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
        <TablePagination
        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
        component="div"
        count={portfolios.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
                

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default PortfolioSearchConfig;